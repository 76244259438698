<template>
  <div class="my-page-participant my-page">
    <ul class="nav" :class="{skeleton: !state.loaded.list}">
      <li class="nav-item" v-for="(m, idx) in state.menus" :key="idx">
        <router-link :to="m.path" :class="{'badge-point-soft': state.menu.name === m.name}" class="no-underline">{{ m.title }}</router-link>
      </li>
    </ul>
    <div class="content">
      <div class="wrapper" ref="wrapRef">
        <template v-if="state.projects.length">
          <div class="status" :class="{skeleton: !state.loaded.amount}">
            <div class="rect shadow">
              <div class="subject font-sm">
                <span v-if="state.projectType === 'mock'">모의투자 가능 잔액</span>
                <span v-else-if="state.projectType === 'invest'">현재 투자 중인 금액</span>
                <span v-else>현재 후원 중인 금액</span>
              </div>
              <div class="info">
                <span class="img" style="background-image: url(/assets/ico/page.mypage.menu.total-amount.svg)"></span>
                <span class="value">{{ state.amounts.current }}원</span>
              </div>
            </div>
            <div class="rect shadow">
              <div class="subject font-sm">
                <span v-if="state.projectType === 'mock'">모의투자한 총 금액</span>
                <span v-else-if="state.projectType === 'invest'">총 투자 금액</span>
                <span v-else>총 후원 금액</span>
              </div>
              <div class="info">
                <span class="img" style="background-image: url(/assets/ico/page.mypage.menu.total-amount.svg)"></span>
                <span class="value">{{ state.amounts.total }}원</span>
              </div>
            </div>
          </div>
          <div class="table-outer table-responsive-sm" v-if="state.projectType === 'mock' && state.contests.length">
            <table class="table font-sm">
              <thead>
              <tr>
                <th>모의투자 대회</th>
                <th class="period">대회기간</th>
                <th>투자금액</th>
                <th>투자가능잔액</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(c, idx) in state.contests" :key="idx">
                <td>
                  <router-link :to="`/partnership/${c.contestSeq}/mock`">{{ c.contestName }}</router-link>
                </td>
                <td class="period">{{ c.fundingStartDate }}~{{ c.fundingEndDate }}</td>
                <td>{{ $lib.getNumberFormat(c.expenseAmt) }}{{ c.currencyUnit }}</td>
                <td>{{ $lib.getNumberFormat(c.investPossibleLimit - c.expenseAmt) }}{{ c.currencyUnit }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <ul class="tight">
            <li class="project" :class="{skeleton: !state.loaded.list }" v-for="(p, idx) in state.projects" :key="idx" :data-project-seq="getSequence(p)">
              <template v-if="state.projectType === 'invest'">
                <div class="thumb">
                  <router-link :to="`/invest/${p.investSeq}`" class="img" title="프로젝트로 이동"
                               :style="{ backgroundImage: `url(${$definitions.urls.imgCdn + p.thumbFilePath})`, backgroundPosition: p.mainImagePosition ? p.mainImagePosition : 'center' }"></router-link>
                </div>
                <div class="info">
                  <div class="top">
                    <div class="color-secondary font-xs">
                      <span>투자일 {{ p.accountDate }}</span>
                    </div>
                    <router-link class="title no-underline d-inline-block" :to="`/invest/${p.investSeq}`" :title="p.projectName">{{ p.projectName }}</router-link>
                    <div class="amount">
                      <b>{{ $lib.getNumberFormat(p.myAmt) }}원 </b>
                    </div>
                  </div>
                  <div class="badges">
                    <span class="badge badge-point-soft">{{ p.stockTypeText }}</span>
                    <span class="badge badge-secondary-soft" v-if="p.fundingType === '02'">사모</span>
                    <span class="badge badge-secondary-soft" v-if="p.rewardFlag === 'Y'">리워드</span>
                    <span class="badge badge-secondary-soft" v-if="p.incomeDeductionFlag === 'Y'">소득공제</span>
                    <template v-if=" p.regState === 'STATUS_0006'">
                      <template v-if="p.cancelFlag === 'Y'">
                        <span class="badge badge-secondary-soft">투자 취소</span>
                      </template>
                      <template v-else-if="p.cancelFlag === 'N' && p.endFlag === 'Y'">
                        <span class="badge badge-secondary-soft" v-if="p.successFailFlag === 'S'">펀딩 성공</span>
                        <span class="badge badge-secondary-soft" v-if="p.successFailFlag === 'F'">펀딩 실패</span>
                      </template>
                      <template v-else-if="p.cancelFlag === 'N' && p.endFlag === 'N'">
                        <span class="badge badge-secondary-soft">진행중</span>
                      </template>
                    </template>
                    <template v-else-if=" p.regState === 'STATUS_0007'">
                      <span class="badge badge-secondary-soft">보류</span>
                    </template>
                  </div>
                  <div class="actions d-flex d-lg-none">
                    <button class="btn btn-light font-xs" @click="open(p.subscribeSeq)">자세히 보기</button>
                    <a class="btn btn-light font-xs" :href="`/bonds/${p.investSeq}/${p.subscribeSeq}`" target="_blank" v-if="p.investSeq && (p.investSeq.toString() === '358')">채권 확인</a>
                    <button class="btn btn-light font-xs" @click="sign(p)" v-if="p.investSeq === 240 || (p.fundingType === '02' && p.stockTypeText === '채권' && p.successFailFlag === 'S' && p.endFlag === 'Y')">온라인 서명</button>
                  </div>
                </div>
                <div class="actions d-lg-flex d-none">
                  <button class="btn btn-light font-xs" @click="open(p.subscribeSeq)">자세히 보기</button>
                  <a class="btn btn-light font-xs" :href="`/bonds/${p.investSeq}/${p.subscribeSeq}`" target="_blank" v-if="p.investSeq && (p.investSeq.toString() === '358')">채권 확인</a>
                  <button class="btn btn-light font-xs" @click="sign(p)" v-if="p.investSeq === 240 || (p.fundingType === '02' && p.stockTypeText === '채권' && p.successFailFlag === 'S' && p.endFlag === 'Y')">온라인 서명</button>
                </div>
              </template>
              <template v-else>
                <div class="thumb">
                  <router-link :to="`/reward/${p.projectSeq}`" class="img" title="프로젝트로 이동"
                               :style="{ backgroundImage: `url(${$definitions.urls.imgCdn + p.thumbFilePath})`, backgroundPosition: p.mainImagePosition ? p.mainImagePosition : 'center' }"></router-link>
                </div>
                <div class="info">
                  <div class="top">
                    <div class="color-secondary font-xs">
                      <span>{{ state.projectType !== "mock" ? "후원일" : "투자일" }} </span>
                      <span>{{ p.regDate }}</span>
                    </div>
                    <router-link class="title no-underline d-inline-block" :to="`/reward/${p.projectSeq}`" :title="p.projectName"><b>{{ p.projectName }}</b>
                    </router-link>
                    <div class="option">
                      <span class="color-secondary" v-if="state.projectType === 'mock'">대회명 {{ p.contestName }}</span>
                      <template v-else>
                        <span class="color-secondary" v-if="p.rewardCount">{{ p.rewardTitle }}</span>
                        <span class="color-secondary" v-else>리워드 없이 참여</span>
                      </template>
                    </div>
                    <div class="amount">
                      <b v-if="state.projectType === 'reward'">{{ $lib.getNumberFormat(p.myAmt) }}원</b>
                      <b v-else>{{ $lib.getNumberFormat(p.myAmt) }}{{ p.currencyUnit }}</b>
                    </div>
                  </div>
                  <div class="badges">
                    <template v-if="p.fundingType === 'A'">
                      <span class="badge badge-point-soft">성공해야 리워드</span>
                      <span class="badge badge-secondary-soft">예약 결제({{ p.paymethod }})</span>
                    </template>
                    <template v-else-if="p.fundingType === 'K'">
                      <span class="badge badge-point-soft">무조건 리워드</span>
                      <span class="badge badge-secondary-soft">
                      <template v-if="p.incomingChk === 0">
                        <span>{{ p.paymethod }}</span>
                        <span v-if="!['포인트', '포인트 + 쿠폰'].includes(p.paymethod) && p.omcpay > 0">/포인트</span>
                      </template>
                      <template v-else-if="p.incomingChk === 1 && p.paymethod === '가상계좌'">
                        <span>가상계좌(참여예약중)</span>
                        <span v-if="!['포인트', '포인트 + 쿠폰'].includes(p.paymethod) && p.omcpay > 0">/포인트</span>
                      </template>
                      </span>
                    </template>
                    <template v-if="p.regState === 'STATUS_0006'">
                      <span class="badge" :class="p.regStateName === '진행중' ? 'badge-primary-soft' : 'badge-secondary-soft'" v-if="p.progressOrder === '1'">{{ p.regStateName }}</span>
                      <span class="badge badge-danger-soft" v-else-if="p.progressOrder === '2'">
                        <template v-if="p.successFailFlag === 'S'">펀딩 성공</template>
                        <template v-else-if="p.successFailFlag === 'F'">{{ p.fundingType === "A" ? "펀딩 실패" : "펀딩 마감" }}</template>
                      </span>
                    </template>
                    <span class="badge" :class="p.regStateName === '진행중' ? 'badge-primary-soft' : 'badge-secondary-soft'" v-if="p.regState === 'STATUS_0007'">{{ p.regStateName }}</span>
                  </div>
                  <div class="actions d-flex d-lg-none">
                    <template v-if="state.projectType === 'mock'">
                      <button class="btn btn-light font-xs" @click="cancel(p)" v-if="p.progressOrder === '1'">투자 취소</button>
                    </template>
                    <template v-else>
                      <button class="btn btn-light font-xs" @click="open(p.investorSeq)">자세히 보기</button>
                      <button class="btn btn-point font-xs" @click="openReview(p.investorSeq, p.projectSeq)" v-if="computedShowReviewBtn(p)">후기 작성하기</button>
                    </template>
                  </div>
                </div>
                <div class="actions d-lg-flex d-none">
                  <template v-if="state.projectType === 'mock'">
                    <button class="btn btn-light font-xs" @click="cancel(p)" v-if="p.progressOrder === '1'">투자 취소</button>
                  </template>
                  <template v-else>
                    <button class="btn btn-light font-xs" @click="open(p.investorSeq)">자세히 보기</button>
                    <button class="btn btn-point font-xs" @click="openReview(p.investorSeq, p.projectSeq)" v-if="computedShowReviewBtn(p)">후기 작성하기</button>
                  </template>
                </div>
              </template>
            </li>
          </ul>
          <Pagination :info="state.pagination" :change="load" v-if="state.pagination"/>
        </template>
        <div class="pt-10 pb-10" v-else-if="state.loaded.list">
          <NoData/>
        </div>
      </div>
    </div>
    <ul class="guide bottom tight font-sm" v-if="state.projectType === 'reward'">
      <li>후원하기 취소는 펀딩 종료일 1일 전까지 가능하며, 펀딩 종료일 당일 및 펀딩 마감 이후에는 취소가 불가능합니다. 또한 펀딩 기간 중 리워드가 배송된 경우에도 취소가 불가능하오니 이 부분 양해 바랍니다.</li>
      <li>배송정보 수정은 펀딩 종료일까지 수정이 가능합니다. 수정은 프로젝트 [자세히보기] > [배송지 정보] > [수정] 버튼을 통해 가능합니다. 다만, 펀딩기간 중 리워드가 배송되는 프로젝트라면 참여한 프로젝트 진행자에게 문의해주세요.</li>
      <li>가상계좌는 유효 기간 내에 입금을 진행해주세요.</li>
      <li>금액대별 리워드 변경을 원할 시 기존 참여를 취소해주시고, 다시 참여 해주세요. 리워드 옵션 변경 시에는 관리자 문의를 이용해주세요.</li>
    </ul>
    <ul class="guide bottom tight font-sm" v-else-if="state.projectType === 'invest'">
      <li>투자취소는 투자마감시각까지 가능합니다.</li>
      <li>투자금액 변경은 투자취소 후 재투자를 통해 가능하며, 이에 따라 선착순 배정 순서가 변경될 수 있습니다.</li>
    </ul>
  </div>
</template>

<script>
import {computed, defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Card from "@/components/Card.vue";
import router from "@/scripts/router";
import NoData from "@/components/NoData";
import PieChart from "@/components/PieChart";
import store from "@/scripts/store";
import Pagination from "@/components/Pagination";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageMyPageParticipant";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Pagination, PieChart, NoData, Card},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "펀딩 프로젝트");

      const pathArr = router.app.$route.path?.split("/");
      state.menu = state.menus[0];

      if (pathArr?.length > 3) {
        state.projectType = pathArr[3];
      }

      for (let i in state.menus) {
        if (router.app.$route.path.startsWith(state.menus[i].path)) {
          state.menu = state.menus[i];
          break;
        }
      }

      state.projectType && load();

      // temp code for testing
      // setTimeout(() => {
      //   state.projectTypes = [
      //     {title: "후원형", amount: 8000000, count: 190},
      //     {title: "증권형", amount: 2000000, count: 24},
      //   ];
      //
      //   state.projectCategories = [
      //     {title: "테크", amount: 2140000, count: 30},
      //     {title: "캠페인", amount: 870000, count: 14},
      //     {title: "리빙", amount: 2000000, count: 52},
      //     {title: "환경", amount: 3000000, count: 76},
      //     {title: "푸드", amount: 1990000, count: 42}
      //   ];
      // });
    });

    const state = reactive({
      projectType: "",
      loaded: {
        list: false,
        amount: false
      },
      contests: [],
      amounts: {
        current: "0000000",
        total: "0000000",
      },
      menu: {
        name: "",
        title: "",
        path: ""
      },
      menus: [
        // {name: "all", title: "전체", path: "/mypage/participant"},
        {name: "reward", title: "후원형", path: "/mypage/participant/reward"},
        {name: "invest", title: "증권형", path: "/mypage/participant/invest"},
        {name: "mock", title: "모의투자", path: "/mypage/participant/mock"},
      ],
      projectTypes: [],
      projectCategories: [],
      projects: [],
      pagination: null,
      acCfmResultCdList: [
        {name: "", detail: "",}
      ],
    });

    const wrapRef = ref();

    const computedShowReviewBtn = computed(() => {
      return (project) => {
        if (!project) return false;

        return (project.deliveryYn === "Y" || new window.Date(project.investEndDate) <= new window.Date())
            && project.wroteReview === "N"
            && project.incomingChk === 0
            && project.rewardCount
            && (project.fundingType === "K" || (project.fundingType === "A" && project.successFailFlag === "S"));
      };
    });

    const open = (detailSeq) => {
      store.commit("openModal", {
        name: "Participant",
        params: {
          detailSeq,
          projectType: state.projectType
        },
        callback: `${component.name}.load`
      });
    };

    const openReview = (investorSeq, projectSeq) => {
      store.commit("confirm", {
        message: "리워드를 받으셨나요? 꼭 받으신 후에 작성해주세요!",
        allowTxt: "받았어요",
        disallowTxt: "아직 받지 못했어요",
        allow() {
          store.commit("openModal", {
            name: "Review",
            params: {
              investorSeq,
              projectSeq
            }
          });
        },
        disallow() {
          store.commit("setSwingMessage", "리워드를 받으신 후에 후기를 작성해주세요.");
        }
      });
    };

    const openReviewEdit = (investorSeq, projectSeq, id) => {
      store.commit("openModal", {
        name: "Review",
        params: {
          investorSeq,
          projectSeq,
          edit: true,
          id,
        }
      });
    };

    const cancel = async (p) => {
      store.commit("confirm", {
        message: `프로젝트(${p.projectName})의 투자를 취소하시겠습니까?`,
        allow() {
          http.put(`/api/participant/mock/projects/${p.investorSeq}`).then(() => {
            store.commit("setSwingMessage", "해당 프로젝트의 투자를 취소하였습니다.");
            load();
          }).catch(httpError());
        }
      });
    };

    const sign = (project) => {
      store.commit("openModal", {
        name: "SignaturePad",
        params: {project},
        callback: `${component.name}.load`
      });
    };

    const getSequence = (project) => {
      if (state.projectType === "invest") {
        return project.investSeq;
      }

      return project.projectSeq;
    };

    const load = async (num) => {
      const args = {
        pageIndex: num || 1,
        pageSize: 5,
        pageUnit: 25,
      };

      num && window.scrollTo({top: 0, left: 0, behavior: "smooth"});

      for (let i = 0; i < 3; i += 1) {
        state.projects.push({
          projectName: "Wait a moment",
          rewardTitle: "Wait",
          paymethod: "Wait",
          myAmt: 1000000,
          targetAmt: 1000000,
          subscribeAmt: 1000000,
          expenseAmt: 1000000,
          investStartDate: "2021-12-20",
          investEndDate: "2021-12-21",
          fundingStartDate: "2021-12-20",
          fundingEndDate: "2021-12-21",
          regDate: "2021-12-20",
          stockTypeText: "wait",
        });
      }

      state.loaded.list = false;
      state.loaded.amount = false;

      if (state.projectType === "reward") {
        args.mockFlag = "N";
      } else if (state.projectType === "mock") {
        args.mockFlag = "Y";
      }

      const res1 = await http.get(`/api/participant/${state.projectType}/projects`, args).catch(httpError());
      state.loaded.list = true;

      if (res1.error) {
        return;
      }

      if (state.projectType === "reward" && Array.isArray(res1?.data?.body?.rewardProjectList)) {
        state.projects = res1.data.body.rewardProjectList;
      } else if (state.projectType === "invest" && Array.isArray(res1?.data?.body?.investProjectList)) {
        state.projects = res1.data.body.investProjectList;
        state.acCfmResultCdList = res1.data.body.acCfmResultCdList;
      } else if (state.projectType === "mock" && Array.isArray(res1?.data?.body?.mockProjectList)) {
        state.projects = res1.data.body.mockProjectList;
      }

      for (let i in state.projects) {
        state.projects[i].rewardTitle = state.projects[i].rewardTitle?.replace("<br>", " ");
      }

      state.pagination = res1?.data?.body?.paginationInfo;

      const pathArr = router.app.$route.path?.split("/");
      const detailSeq = pathArr[pathArr.length - 1];

      const goWithoutDetailSeq = (func) => {
        pathArr.splice(pathArr.length - 1, 1);
        router.replace({path: pathArr.join("/")});
        typeof func === "function" && func();
      };

      if (!state.projects.length) {
        lib.isNumeric(detailSeq) && goWithoutDetailSeq();
        return;
      }

      state.projects.forEach(p => p.regStateName = p.regStateName === "진행" ? "진행중" : p.regStateName);

      const res2 = await http.get(`/api/participant/${state.projectType}/projects`, args).catch(httpError());
      state.loaded.amount = true;

      if (res2.error) {
        return;
      }

      if (state.projectType === "mock") {
        state.amounts.current = lib.getNumberFormat(window.Number(res2.data.body.investableAmt));
        state.amounts.total = lib.getNumberFormat(res2.data.body.expenseAmount);

        http.get("/api/participant/mock/projects", {
          pageIndex: 1, pageSize: 5, pageUnit: 5,
        }).then(({data}) => {
          state.contests = data.body.contestList;
        });
      } else {
        state.amounts.current = lib.getNumberFormat(res2.data.body.currentAmount);
        state.amounts.total = lib.getNumberFormat(res2.data.body.totalAmount);

        if (lib.isNumeric(detailSeq)) {
          goWithoutDetailSeq(() => {
            open(detailSeq);
          });
        } else if (router.app.$route.hash) {
          const sequence = router.app.$route.hash.substring(1);

          if (lib.isNumeric(sequence)) {
            const $elem = document.querySelector(`[data-project-seq="${sequence}"]`);

            if ($elem) {
              const top = $elem.getBoundingClientRect().top - lib.getHeaderHeight(true) - 20;
              window.scrollTo({top});
            }
          }
        }
      }
    };

    return {
      component
      , state
      , wrapRef
      , computedShowReviewBtn
      , getSequence
      , load
      , open
      , openReview
      , openReviewEdit
      , cancel
      , sign
    };
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-participant {
  > .content {
    > .wrapper {
      .status {
        display: flex;
        gap: $px16;

        .rect {
          padding: $px20;
          transition: border-color 0.18s;
          border-radius: $px16;
          flex-basis: $px250;

          .subject {
            font-size: $px14;
            margin-bottom: $px16;
          }

          .info {
            display: flex;
            gap: $px6;
            align-items: center;

            .img {
              width: $px24;
              height: $px24;
            }

            .value {
              font-weight: 600;
              font-size: $px16;
            }
          }
        }
      }

      .table-outer {
        margin-top: $px24;

        table {
          th, td {
            border: 0;
            padding: $px8;
          }

          th {
            background: $colorBackground;
          }
        }
      }

      ul {
        margin-top: $px24;

        > li {
          display: flex;
          gap: $px24;

          .thumb {
            width: 24%;
            flex-shrink: 0;

            .img {
              background-color: $colorBackground;
              width: 100%;
              padding-top: 100%;
              border-radius: $thumbBorderRadius;
              background-size: auto 100%;
            }
          }

          .info {
            font-size: $px14;
            padding: $px12 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .top {
              display: flex;
              flex-direction: column;
              gap: $px4;

              > .title {
                font-size: $px18;
              }

              .amount {
                font-size: $px16;
              }
            }

            .badges {
              display: flex;
              align-items: center;
              gap: $px4;
              flex-wrap: wrap;

              .badge {
                border-radius: $px11;
                font-size: $px12;
                padding: $px8 $px16;
              }
            }
          }

          .actions {
            align-self: center;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            gap: $px8;

            .btn {
              display: block;
              padding: $px8 $px16;
              width: 100%;
            }
          }

          &:not(:first-child) {
            margin-top: $px20;
          }
        }
      }

      .page {
        margin-top: $px25;
      }
    }
  }

  > .guide {
    margin-top: $px24;
  }

  .nav.skeleton li a {
    @include skeleton;
  }

  .content {
    > .wrapper {
      .status.skeleton {
        b, span {
          @include skeleton;
        }
      }

      ul > li.skeleton {
        .thumb > .img {
          @include skeleton;
        }

        .info {
          .top {
            a, span, b {
              @include skeleton;
            }
          }

          .badges .badge {
            @include skeleton;
          }
        }

        .actions > .btn {
          @include skeleton;
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .content > .wrapper {
      .status {
        flex-direction: column;

        .rect {
          flex-basis: initial;
        }
      }

      ul > li {
        gap: $px16;

        .thumb {
          width: 30%;
          font-size: 0;
        }

        .info {
          padding: $px4 0 0 0;
          justify-content: initial;
          gap: $px10;

          .top {
            .title {
              font-size: $px16;
            }

            .option {
              font-size: $px12;
            }

            .amount {
              font-size: $px14;
            }
          }

          .badges > .badge {
            font-size: $px12;
            padding: $px6 $px10;
            border-radius: $px8;
          }

          .actions {
            align-self: initial;
            align-items: center;
            flex-direction: row;
            gap: $px8;
            width: 100%;

            > .btn {
              width: auto;
              word-break: keep-all;
            }
          }
        }

        &:not(:last-child) {
          margin-top: $px36;
        }
      }

      .table-outer table {
        th, td {
          white-space: nowrap;
          word-break: keep-all;
          padding: $px8;

          &.period {
            display: none;
          }
        }

        td {
          &:not(&.period) {
            white-space: pre-wrap;
          }
        }
      }
    }
  }

}
</style>